import {
  createSlice,
  nanoid,
  createAsyncThunk,
  createEntityAdapter,
  EntityState,
  EntityId,
} from "@reduxjs/toolkit";

import {
  addExchange,
  deleteExchange,
  getExchanges,
  updateExchange,
} from "../services/exchangesSevice";
import { IExchanges } from "Common/types";
import { RootState } from "../slices";


const exchangeAdapter = createEntityAdapter<IExchanges,EntityId>({
    selectId: (e:any) => e._id,
    sortComparer: (a, b) => a._id.localeCompare(b._id),
});


const initialState =exchangeAdapter.getInitialState({
    status: 'idle',
    error: null,
  });

export const fetchExchanges = createAsyncThunk(
  "exchanges/fetchExchanges",
  async () => {
    const response = await getExchanges();
    return response?.data.exchanges;
  }
);

export const updateExchanges = createAsyncThunk(
    "exchanges/updateExchanges",
    async (exchangeUpdate:IExchanges) => {
        const response = await updateExchange(exchangeUpdate);
        return response?.data;
    }
);

export const addExchanges = createAsyncThunk(
    "exchanges/addExchanges",
    async (exchangeAdd:IExchanges) => {
        const response = await addExchange(exchangeAdd);
        return response?.data;
    }
);
export const deleteExchanges = createAsyncThunk(
    "exchanges/deleleExchanges",
    async (exchangeDelete:IExchanges) => {
        const response = await deleteExchange(exchangeDelete._id);
       
        return response?.data
    }
);

const exchangeSlice=createSlice({
    name: "exchanges",
    initialState:initialState,
    reducers: {
        exchangeAdded(state, action) {
          const exchange = action.payload;
          state.entities[exchange._id] = exchange;
        },
        exchangeUpdated(state, action) {
          const updatedExchange = action.payload;
          const exchangeId = updatedExchange._id;
          state.entities[exchangeId] = updatedExchange;
        },
        exchangeDeleted(state, action) {
          //delete state.entities[action.payload._id];
          exchangeAdapter.removeOne(state, action.payload);
        }
      },
    extraReducers: (builder) => {
        builder
            .addCase(fetchExchanges.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchExchanges.fulfilled, (state, action) => {
                state.status = "idle";
                exchangeAdapter.upsertMany(state, action.payload);
               
            })
            .addCase(fetchExchanges.rejected, (state, action) => {
                state.status = "failed";
                // state.error = action.error.message;
            })
            .addCase(addExchanges.fulfilled,(state, action) => {
                exchangeAdapter.addOne(state, action.payload);
            })
            .addCase(updateExchanges.fulfilled,(state, action) => {
               const updatedExchange = action.payload;
               exchangeAdapter.updateOne(state,{id:updatedExchange._id,changes:updatedExchange} );
            })
            .addCase(deleteExchanges.fulfilled,(state, action) => {
                exchangeAdapter.removeOne(state, action.payload._id);
            })
     }
})
export const { exchangeAdded, exchangeUpdated, exchangeDeleted } = exchangeSlice.actions;
export const {
    selectAll: selectAllExchanges,
    selectById: selectExchangeById
    
  } = exchangeAdapter.getSelectors((state:RootState) => state.exchanges);

  export const exchangesReducer = exchangeSlice.reducer;
