import axios from "axios";
import { ICurrencyTypes } from "Common/types";

const apiurl=process.env.REACT_APP_SERVER_URL

export const getPairType = () => {
  const token = localStorage.getItem("token");
  
  if (token) {
    try {
      
      const url = `${apiurl}pairTypes`;
      const response = axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching PairTypes:", error);
    }
  } else {
  }
};

export const deletePairType = (PairTypeId: string) => {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      const url = `${apiurl}pairTypes/${PairTypeId}`;
      const response = axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error deleting PairTypes:", error);
    }
  }
};

export const addPairType = (PairType: ICurrencyTypes) => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const url = `${apiurl}pairTypes`;
      const response = axios.post(url, PairType, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      console.error("Error adding new PairTypes:", error);
    }
  }
};

export const updatePairType= (PairType: ICurrencyTypes) => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const url = `${apiurl}pairTypes/${PairType._id}`;
      const response = axios.put(url, PairType, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      console.error("Error updating  PairTypes:", error);
    }
  }
};
