import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Link } from "react-router-dom";
import { Dropdown } from "Common/Components/Dropdown";
import TableContainer from "Common/TableContainer";
import Flatpickr from "react-flatpickr";
import Select, { ActionMeta, MultiValue, SingleValue } from "react-select";
import {
  addPairSymbol,
  deletePairSymbol,
  getPairSymbol,
  updatePairSymbol,
} from "services/pairSymbolService";
import "flatpickr/dist/flatpickr.css";
// Icons
import {
  Search,
  Trash2,
  Plus,
  MoreHorizontal,
  FileEdit,
  DatabaseZap,
  DatabaseBackup,
} from "lucide-react";
import { ToastContainer, ToastPosition, toast } from "react-toastify";

import DeleteModal from "Common/DeleteModal";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { IExchanges } from "Common/types";

import { deleteDataTable, getDataTable, keepLastDataTable } from "services/dataTableSevice";
import { IPairSymbol } from "./PairSymbol";
import { getExchangeName } from "services/exchangeNamesService ";
import { IExchangeNames } from "./ExchangeName";

export interface Option {
  readonly label: string;
  readonly value?: string;
  readonly options?: Option[];
  readonly isDisabled?: boolean;
}

const customStyles = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
};
const DataTable = () => {
  const FetchedSuccessToast = () => toast.info("Data Fetched successfully ", { autoClose: 3000, theme: "colored", icon: true });
  const NodataToast = () => toast.info("Record not found", { autoClose: 3000, theme: "colored", icon: true });
  const DataClearedToast = () => toast.info("Data Cleaned successfully ", { autoClose: 3000, theme: "colored", icon: true });
  const DataClearedErrorToast = () => toast.error("Data Cleaned Error ", { autoClose: 3000, theme: "colored", icon: true });
  const DataDeletedToast = (message:string) => toast.info(`Data deleted ${message}`, { autoClose: 3000, theme: "colored", icon: true });

  const [selectedExchange, setselectedExchange] = useState<MultiValue<Option>
  >([]);
  const [selectedSymbols, setSelectedSymbols] = useState<MultiValue<Option>>(
    []
  );
  const [prices,setPrices] = useState<Number>();
  const [dataTable, setDataTable] = useState<[]>([]);
  const [startdate, setstartdate] = useState<Date>(new Date());
  const [enddate, setenddate] = useState<Date>(new Date());
  const [PairSymbol, setPairSymbol] = useState<IPairSymbol[]>([]);
  const [ExchangeNames, setExchangeNames] = useState<IExchangeNames[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const exchangeNamesOptions: Option[] = [{ label: "All", value: "All" }];
  const pairNamesOptions: Option[] = [{ label: "All", value: "All" }];

  useEffect(() => {
    const fetchPairSymbol = async () => {
      setIsLoading(true);
      const token = localStorage.getItem("token");

      if (token) {
        try {
          const symbolresponse = await getPairSymbol();
          setPairSymbol(symbolresponse?.data.pairSymbols);

          const exchangerespone = await getExchangeName();
          setExchangeNames(exchangerespone?.data.exchangeNames);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching PairSymbols:", error);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchPairSymbol();
  }, []);

  PairSymbol.forEach((item) => {
    pairNamesOptions.push({
      label: item.name!,
      value: item._id,
    });
  });

  ExchangeNames.forEach((item) => {
    exchangeNamesOptions.push({
      label: item.name!,
      value: item._id,
    });
  });
  const fetchDataTable = async () => {
   
    const token = localStorage.getItem("token");
    if (token) {
      try {
        setIsLoading(true);
        const response = await getDataTable(startdate.toISOString(),
          enddate.toISOString(),
          selectedExchange.map((item) => item.value!),
          selectedSymbols.map((item) => item.value!),
          prices!
        );

        setDataTable(response?.data);
        if (response?.data.length>0) {
          FetchedSuccessToast()
        }
        else {
          NodataToast()
        }
        setIsLoading(false);

      } catch (error) {
        console.error("Error fetching currency types:", error);
      }
    }
  };
  const cleanPrices = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        setIsLoading(true);
        const response = await keepLastDataTable(
          startdate.toISOString(),
          enddate.toISOString()
        );
        if (response?.data.result=="OK") {
          DataClearedToast()
        }
        else {
          DataClearedErrorToast()
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching currency types:", error);
      }
    }
  };

  const [eventData, setEventData] = useState<IExchanges | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);

    if (cell._id) {
      setEventData(cell);
      handleDelete();
    }
  };

  const handleDelete = async () => {
    if (eventData) {
      setDeleteModal(false);
      const token = localStorage.getItem("token");

      if (token) {
        try {
        const res = await deleteDataTable(eventData._id);
       
        DataDeletedToast(res?.data.message)
        } catch (error) {
          console.error("Error deleting datatable:", error);
        }
      }
    }
  };
  const handelNumberchange = (e:any) => {e.preventDefault();setPrices(e.target.value)}
  const handleUpdateDataClick = (ele: IExchanges) => {
    setEventData({ ...ele });
    ele.pairPrices.map((pairPrice) => {
   
    });
    setIsEdit(true);
    setShow(true);
  };

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: (eventData && eventData._id) || "",
      faName: (eventData && eventData.faName) || "",
      currencyType: (eventData! && eventData.currencyType) || "",
      exchangeName: (eventData! && eventData.exchangeName) || "",
      urlAddress: (eventData && eventData.urlAddress) || "",
      pairPrices: (eventData && eventData.pairPrices) || [],
    },
    validationSchema: Yup.object({
      faName: Yup.string().required("Please Enter Name"),
    }),

    onSubmit: (values: IExchanges) => {
      if (isEdit) {
        const updateExchange = {
          _id: eventData!._id,
        };

        // handleUpdate(updateExchange);
      } else {
        //handleAdd(newExchange);
      }
      toggle();
    },
  });
  //
  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData(null);
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData(null);

      validation.resetForm();
    }
  }, [show, validation]);
  function handleSymbols(
    newValue: MultiValue<Option>,
    actionMeta: ActionMeta<Option>
  ): void {
    setSelectedSymbols(newValue);
  }
  function handleexchangename(
    newValue: MultiValue<Option>,
    actionMeta: ActionMeta<Option>
  ): void {
    setselectedExchange(newValue);
  }
  const columns = useMemo(
    () => [
      {
        header: "ID",
        accessorKey: "_id._id",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link
            to="#!"
            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
          >
            {cell.getValue()}
          </Link>
        ),
      },
      {
        header: "ExchangeName",
        accessorKey: "exchangeName.name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "pairSymbol",
        accessorKey: "pairSymbol.name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "pair",
        accessorKey: "pair.name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "sellPrice",
        accessorKey: "sellPrice",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "priority",
        accessorKey: "priority",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "timeDate",
        accessorKey: "timeDate",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <Dropdown className="relative">
            <Dropdown.Trigger
              className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
              id="usersAction1"
            >
              <MoreHorizontal className="size-3" />
            </Dropdown.Trigger>
            <Dropdown.Content
              placement="right-end"
              className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600"
              aria-labelledby="usersAction1"
            >
              <li>
                <Link
                  data-modal-target="addUserModal"
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  to="#!"
                  onClick={() => {
                    const data = cell.row.original;
                    handleUpdateDataClick(data);
                  }}
                >
                  <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Edit</span>
                </Link>
              </li>
              <li>
                <Link
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  to="#!"
                  onClick={() => {
                    const datatable = cell.row.original;
                    onClickDelete(datatable);
                  }}
                >
                  <Trash2 className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Delete</span>
                </Link>
              </li>
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <BreadCrumb title="DataTable" pageTitle="DataTable" />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      {isLoading ?(
         <div className="inline-block size-8 border-2 rounded-full animate-spin border-l-transparent border-custom-500"></div>
      ):(
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
            <div className="xl:col-span-12">
              <div className="card" id="usersTable">
                <div className="card-body">
                  <div className="flex items-center">
                    <h6 className="text-15 grow">DataTable</h6>
                    <div className="shrink-0"></div>
                  </div>
                </div>
                <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
                  <div className="flex items-center ">
                    <p>From</p>
                    <Flatpickr
                      options={{
                        dateFormat: "Y-m-d",
                      }}
                      value={startdate}
                      onChange={([date]) => {
                        setstartdate(date);
                      }}
                      placeholder="Select Date"
                      className="w-1/5 form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    />
                    <p>To</p>
                    <Flatpickr
                      options={{
                        dateFormat: "Y-m-d",
                      }}
                      value={enddate}
                      onChange={([date]) => {
                        setenddate(date);
                      }}
                      placeholder="Select Date"
                      className=" w-1/5 form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    />
                    <button
                      type="button"
                      className="ml-5 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                      onClick={fetchDataTable}
                    >
                      <DatabaseBackup className="inline-block size-4" />{" "}
                      <span className="align-middle">GetData</span>
                    </button>
                    <button
                      type="button"
                      className="ml-5 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                      onClick={cleanPrices}
                    >
                      <DatabaseZap className="inline-block size-4" />{" "}
                      <span className="align-middle">CleanPrices</span>
                    </button>
                  </div>
                  <div className="flex flex-row items-center w-full  pt-5">
                    <div className="flex items-center   w-1/3">
                      <p className="w-1/4">Symbol</p>
                      <Select
                        className=" w-3/4 border-slate-200 dark:border-zinc-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zinc-600 disabled:border-slate-300 dark:disabled:border-zinc-500 dark:disabled:text-zinc-200 disabled:text-slate-500 dark:text-zinc-100 dark:bg-zinc-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zinc-200"
                        id="choices-multiple-default"
                        data-choices
                        name="choices-multiple-default"
                        defaultValue={selectedSymbols}
                        isMulti
                        options={pairNamesOptions}
                        menuPortalTarget={document.body}
                        styles={customStyles}
                        onChange={handleSymbols}
                        value={selectedSymbols}
                      />
                    </div>
                    <div className="flex items-center w-1/3 ">
                      <p className="w-1/4 mr-5 ml-5">ExchangeNames:</p>
                      <Select
                        className="w-3/4 border-slate-200 dark:border-zinc-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zinc-600 disabled:border-slate-300 dark:disabled:border-zinc-500 dark:disabled:text-zinc-200 disabled:text-slate-500 dark:text-zinc-100 dark:bg-zinc-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zinc-200"
                        id="choices-multiple-default"
                        data-choices
                        name="choices-multiple-default"
                        defaultValue={selectedExchange}
                        isMulti
                        options={exchangeNamesOptions}
                        menuPortalTarget={document.body}
                        styles={customStyles}
                        onChange={handleexchangename}
                        value={selectedExchange}
                      />
                    </div>
                    <div className="flex items-center   w-1/3">
                      <p className="w-1/4 ml-5">Price :</p>
                      <input onChange={handelNumberchange}  type="text" className="w-3/4 border h-9"></input>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {dataTable && dataTable.length > 0 ? (
                    <TableContainer
                      isPagination={true}
                      columns={columns || []}
                      data={dataTable || []}
                      customPageSize={20}
                      divclassName="-mx-5 -mb-5 overflow-x-auto"
                      tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                      theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                      thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                      tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                      PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                    />
                  ) : (
                    <div className="noresult">
                      <div className="py-6 text-center">
                        <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                        <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                        <p className="mb-0 text-slate-500 dark:text-zink-200">
                          No price data
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
      )

      }
  
    </React.Fragment>
  );
};

export default DataTable;
