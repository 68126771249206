import axios from "axios";
import { IExchanges, IExchangesSendObject } from "Common/types";

const apiurl=process.env.REACT_APP_SERVER_URL

export const getExchanges = () => {
  console.log(apiurl)
  const token = localStorage.getItem("token");
  
  if (token) {
    try {
      
      const url = `${apiurl}exchanges`;
      const response = axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching exchanges:", error);
    }
  } else {
  }
};

export const deleteExchange = (exchangeId: string) => {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      const url = `${apiurl}exchanges/${exchangeId}`;
      const response = axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error deleting exchanges:", error);
    }
  }
};

export const addExchange = (exchange: IExchanges) => {
  const dataToSend:IExchangesSendObject = {
    //_id:exchange._id,
    currencyType: exchange.currencyType._id,
    exchangeName:exchange.exchangeName._id,
    faName: exchange.faName,
    urlAddress: exchange.urlAddress,
    pairPrices: exchange.pairPrices.map((pairPrice) => {
      return {
        pairType: pairPrice.pairType._id,
        elementSelector: pairPrice.elementSelector,
        priority: pairPrice.priority,
        pairSymbol: pairPrice.pairSymbol._id,
        pairName: pairPrice.pairName._id,
        value: pairPrice.value,
      };
    })
  }
  
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const url = `${apiurl}exchanges`;
      const response = axios.post(url, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      console.error("Error adding new exchanges:", error);
    }
  }
};

export const updateExchange = (exchange: IExchanges) => {
  const token = localStorage.getItem("token");
  const dataToSend:IExchangesSendObject = {
    _id:exchange._id,
    currencyType: exchange.currencyType._id,
    exchangeName:exchange.exchangeName._id,
    faName: exchange.faName,
    urlAddress: exchange.urlAddress,
    pairPrices: exchange.pairPrices.map((pairPrice) => {
      return {
        pairType: pairPrice.pairType._id,
        elementSelector: pairPrice.elementSelector,
        priority: pairPrice.priority,
        pairSymbol: pairPrice.pairSymbol._id,
        pairName: pairPrice.pairName._id,
        value: pairPrice.value,
      };
    })
  }
  if (token) {
    try {
      const url = `${apiurl}exchanges/${exchange._id}`;
      const response = axios.put(url, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      return response;
    } catch (error) {
      console.error("Error updating currency type:", error);
    }
  }
};
