import axios from "axios";
import { ICurrencyTypes } from "Common/types";

const apiurl=process.env.REACT_APP_SERVER_URL

export const getCurrencyType = () => {
  const token = localStorage.getItem("token");
  
  if (token) {
    try {
      
      const url = `${apiurl}currencyTypes`;
      const response = axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching CurrencyTypes:", error);
    }
  } else {
  }
};

export const deleteCurrencyType = (CurrencyTypeId: string) => {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      const url = `${apiurl}currencyTypes/${CurrencyTypeId}`;
      const response = axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error deleting CurrencyTypes:", error);
    }
  }
};

export const addCurrencyType = (CurrencyType: ICurrencyTypes) => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const url = `${apiurl}currencyTypes`;
      const response = axios.post(url, CurrencyType, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      console.error("Error adding new exchangeNames:", error);
    }
  }
};

export const updateCurrencyType= (CurrencyType: ICurrencyTypes) => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const url = `${apiurl}currencyTypes/${CurrencyType._id}`;
      const response = axios.put(url, CurrencyType, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      console.error("Error updating  CurrencyTypes:", error);
    }
  }
};
