// dashboard
import Ecommerce from "pages/Dashboards/Ecommerce";

import UserProfile from "pages/Authentication/UserProfile";
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import Register from "pages/Authentication/Register";
import CurrencyType from "pages/Admin/CurrencyType";
import PairName from "pages/Admin/PairName";
import PairSymbol from "pages/Admin/PairSymbol";
import PairType from "pages/Admin/PairType";
import Exchanges from "pages/Admin/Exchanges";
import ExchangeName from "pages/Admin/ExchangeName";
import SettingsComponent from "../pages/RobotSettings/robotsettings";
import DataTable from "pages/Admin/DataTable";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  { path: "/", component: Ecommerce },
  { path: "/settings", component: SettingsComponent },
  { path: "/exchanges", component: Exchanges},
  { path: "/CurrencyType", component: CurrencyType},
  { path: "/PairName", component: PairName},
  { path: "/PairSymbol", component: PairSymbol},
  { path: "/PairType", component: PairType},
  { path: "/ExchangeName", component: ExchangeName},
  { path: "/datatable", component: DataTable},
  
  // profile
  { path: "/user-profile", component: UserProfile },
];

const publicRoutes = [

  // authentication
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },

]

export { authProtectedRoutes, publicRoutes };
