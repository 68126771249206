import axios from "axios";
import {  IPairNames  } from "Common/types";

const apiurl=process.env.REACT_APP_SERVER_URL

export const getPairName = () => {
  const token = localStorage.getItem("token");
  
  if (token) {
    try {
      
      const url = `${apiurl}PairNames`;
      const response = axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching PairNames:", error);
    }
  } else {
  }
};

export const deletePairName = (PairNameId: string) => {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      const url = `${apiurl}PairNames/${PairNameId}`;
      const response = axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error deleting PairNames:", error);
    }
  }
};

export const addPairName = (PairName: IPairNames) => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const url = `${apiurl}PairNames`;
      const response = axios.post(url, PairName, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      console.error("Error adding new PairNames:", error);
    }
  }
};

export const updatePairName= (CurrencyType: IPairNames) => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const url = `${apiurl}PairNames/${CurrencyType._id}`;
      const response = axios.put(url, CurrencyType, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      console.error("Error updating  PairNames:", error);
    }
  }
};
