import React, { FC, Fragment, useState } from "react";
import { getCheckValue } from "services/checkValueService";
import { ICheck } from "Common/types";

const CheckValue: React.FC<ICheck> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [value, setvalue] = useState<string>("");
  const handleClick = async () => {
    const valuetocheck: ICheck = {
      elementSelector: props.elementSelector,
      urlAddress: props.urlAddress,
    };
    setIsLoading(true);
    const response = await getCheckValue(valuetocheck);
    setvalue(response?.data.value);
    setIsLoading(false);
  };
  return (
    <Fragment>
      {isLoading ? (
        <div>
          <button
            type="button"
            className="flex items-center justify-center size-[37.5px] p-0 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-loader2 size-4 animate-spin"
            >
              <path d="M21 12a9 9 0 1 1-6.219-8.56"></path>
            </svg>
          </button>
        </div>
      ) : (
        <div className="flex flex-row items-center">
          <button
            className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
            onClick={(e) => {
              e.preventDefault();
              handleClick();
            }}
          >
            Check
          </button>
          <p className="ml-2 text-lime-700">Value is : {value}</p>
        </div>
      )}
    </Fragment>
  );
};

export default CheckValue;
