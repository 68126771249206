import axios from "axios";
import { IExchanges, IExchangesSendObject } from "Common/types";

const apiurl = process.env.REACT_APP_SERVER_URL;
export interface  IQueryData{
  startdate:string;
  enddate:string;
  exchangenames?:string[];
  symbolNames?:string[];
  price?:Number;

}
export const getDataTable = (startdate: string, enddate: string,exchangeNames:string[],symbolNames:string[],prices:Number) => {
  const token = localStorage.getItem("token");
  const query:IQueryData={
    startdate:startdate,
    enddate:enddate,
    exchangenames:exchangeNames,
    symbolNames:symbolNames,
    price:prices
  }
  if (token) {
    try {
      const url = `${apiurl}datatable/dashboard/getdata`;
      const response = axios.post(
        url,
        query,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error fetching exchanges:", error);
    }
  } else {
  }
};

export const getLastDataTable = (startdate: string, enddate: string) => {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      const url = `${apiurl}datatable/last`;
      const response = axios.post(
        url,
        {
          startdate: startdate,
          enddate: enddate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error deleting exchanges:", error);
    }
  }
};
export const keepLastDataTable = (startdate: string, enddate: string) => {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      const url = `${apiurl}datatable/keeplast`;
      const response = axios.post(
        url,
        {
          startdate: startdate,
          enddate: enddate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error deleting exchanges:", error);
    }
  }
};
export const getLastDataTableByDateExchangeSymbol = (startdate: string, enddate: string,exchangename:string,symbol:string) => {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      const url = `${apiurl}datatable/byexsymname`;
      const response = axios.post(
        url,
        {
          startdate: startdate,
          enddate: enddate,
          exchangename:exchangename,
          symbol:symbol
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error deleting exchanges:", error);
    }
  }
};
export const getLastDataTableByDateSymbol = (startdate: string, enddate: string,symbol:string) => {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      const url = `${apiurl}datatable/bysymbolname`;
      const response = axios.post(
        url,
        {
          startdate: startdate,
          enddate: enddate,
          symbol:symbol
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error deleting exchanges:", error);
    }
  }
};
export const deleteDataTable = (datatableId: any) => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const url = `${apiurl}datatable/${datatableId._id}`;
      const response = axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error deleting PairNames:", error);
    }
  }
};



