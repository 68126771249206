import React from "react";
import { Nav } from "Common/Components/Tab/Nav";
import Tab from "Common/Components/Tab/Tab";
import { User2, Settings, Phone, Home } from "lucide-react";
import BreadCrumb from "Common/BreadCrumb";
import { MainSettings } from './settings';
const SettingsComponent = () => {
  return (
    <React.Fragment>
      <div className="container-fluid group-data-[content=boxed]:max-w-boxed mx-auto">
      <BreadCrumb title="Settings" pageTitle="Settings" />
        <div className="card">
          <div className="card-body">
            <div>
              <Tab.Container defaultActiveKey="settingIcon">
                <Nav className="flex flex-wrap w-full text-sm font-medium text-center border-b border-slate-200 dark:border-zink-500 nav-tabs">

                  <Nav.Item eventKey="settingIcon" className="group">
                    <a
                      href="#!"
                      data-tab-toggle
                      data-target="settingIcon"
                      className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                    >
                      <Settings className="inline-block size-4 mr-1"></Settings>{" "}
                      <span className="align-middle">Robot Settings</span>
                    </a>
                  </Nav.Item>
                  <Nav.Item eventKey="contactIcon" className="group">
                    <a
                      href="#!"
                      data-tab-toggle
                      data-target="contactIcon"
                      className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                    >
                      <Phone className="inline-block size-4 mr-1"></Phone>{" "}
                      <span className="align-middle">Contacts</span>
                    </a>
                  </Nav.Item>
                </Nav>

                <Tab.Content className="mt-5 tab-content">
                  <Tab.Pane eventKey="settingIcon">
                   <MainSettings></MainSettings>
                  </Tab.Pane>
                  <Tab.Pane eventKey="contactIcon">
                    <p className="mb-0">Contact </p>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SettingsComponent;
