import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "Common/Components/Dropdown";
import TableContainer from "Common/TableContainer";
// Icons
import { Search, Trash2, MoreHorizontal, FileEdit, Plus } from "lucide-react";
import Modal from "Common/Components/Modal";
import DeleteModal from "Common/DeleteModal";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";

import {

  IPairNames,

  IPairPriceProps,

  IPairPrices,
  IPairSymbol,
  IpairTypes,
} from "Common/types";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "state/store";
import { RootState } from "slices";
import { getPairName } from "services/pairNameService";
import { getPairType } from "services/pairTypeService";
import { getPairSymbol } from "services/pairSymbolService";
import {
  addPairPrice,
  removeAllPairPrice,
  removePairPrice,
  selectAllPairPrices,
  updatePairPrice,
} from "state/pairPricesSlice";
import CheckValue from "./checkValue";

export const ExchangesPairPrices:React.FC<IPairPriceProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  let pairPrices = useSelector(selectAllPairPrices);
  const [eventDataPairPrice, setEventDataPairPrice] =
    useState<IPairPrices | null>(null);
  const [showPairPrice, setShowPairPrice] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const error = useSelector((state: RootState) => state.exchanges.error);

  const [pairSymbols, setPairSymbols] = useState<IPairSymbol[]>([]);
  const [pairNames, setPairNames] = useState<IPairNames[]>([]);
  const [pairTypes, setPairTypes] = useState<IpairTypes[]>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  useEffect(() => {
    fetchPairSymbols();
    fetchPairNames();
    fetchPairTypes();
  }, []);

  const fetchPairSymbols = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await getPairSymbol();
        setPairSymbols(response?.data.pairSymbols);
      } catch (error) {
        console.error("Error fetching pair symbols:", error);
      }
    }
  };

  const fetchPairNames = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const response = await getPairName();

        setPairNames(response?.data.pairNames);
      } catch (error) {
        console.error("Error fetching pair names:", error);
      }
    }
  };

  const fetchPairTypes = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const response = await getPairType();
        setPairTypes(response?.data.pairTypes);
      } catch (error) {
        console.error("Error fetching pair types:", error);
      }
    }
  };
  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventDataPairPrice(cell);
      handleDeletePairPrice();
    }
  };

  const handleDeletePairPrice = async () => {
    if (eventDataPairPrice) {
      setDeleteModal(false);
      const token = localStorage.getItem("token");

      if (token) {
        try {
          dispatch(removePairPrice(eventDataPairPrice.id));
        } catch (error) {
          console.error("Error deleting exchanges:", error);
        }
      }
    }
  };

  // Update Data
  const handleUpdateDataClick = (ele: IPairPrices) => {
    setEventDataPairPrice({ ...ele });
    setIsEdit(true);
    setShowPairPrice(true);
  };

  const handleUpdatePairPrice = async (updateData: IPairPrices) => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        dispatch(updatePairPrice({ id: updateData.id, changes: updateData }));
      } catch (error) {
        console.error("Error updating currency type:", error);
      }
    }
  };

  const handleAddPairPrice = async (newPairPrice: IPairPrices) => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        dispatch(addPairPrice(newPairPrice));
      } catch (error) {
        console.error("Error adding new exchanges:", error);
      }
    }
  };

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (eventDataPairPrice! && eventDataPairPrice.id) || "",
      pairName: (eventDataPairPrice! && eventDataPairPrice.pairName) || "",
      pairSymbol: (eventDataPairPrice! && eventDataPairPrice.pairSymbol) || "",
      pairType: (eventDataPairPrice! && eventDataPairPrice.pairType) || "",
      priority: (eventDataPairPrice! && eventDataPairPrice.priority) || 0,
      value: (eventDataPairPrice! && eventDataPairPrice.value) || 0,
      elementSelector:
        (eventDataPairPrice! && eventDataPairPrice.elementSelector) || "",
    },
    validationSchema: Yup.object({
      priority: Yup.number().required("Please Enter priority"),
    }),

    onSubmit: (values: IPairPrices) => {
      let pairNameValue;
      let pairSymbolValue;
      let pairTypeValue;
      if (typeof values.pairName === "string") {
        pairNameValue =
          values.pairName == null
            ? pairNames[0]
            : pairNames.find((item) => item._id === String(values.pairName));
      } else {
        pairNameValue =
          values.pairName == null
            ? pairNames[0]
            : pairNames.find((item) => item._id === values.pairName._id);
      }

      const pairName: IPairNames = pairNameValue || pairNames[0];

      if (typeof values.pairSymbol === "string") {
        pairSymbolValue =
          values.pairSymbol == null
            ? pairSymbols[0]
            : pairSymbols.find(
                (item) => item._id === String(values.pairSymbol)
              );
      } else {
        pairSymbolValue =
          values.pairSymbol == null
            ? pairSymbols[0]
            : pairSymbols.find((item) => item._id === values.pairSymbol._id);
      }

      const pairSymbol: IPairSymbol = pairSymbolValue || pairSymbols[0];

      if (typeof values.pairType === "string") {
        pairTypeValue =
          values.pairType == null
            ? pairTypes[0]
            : pairTypes.find((item) => item._id === String(values.pairType));
      } else {
        pairTypeValue =
          values.pairType == null
            ? pairTypes[0]
            : pairTypes.find((item) => item._id === values.pairType._id);
      }
      const pairType: IPairNames = pairTypeValue || pairTypes[0];

      const newPairPrices: IPairPrices = {
        id: values.id,
        pairName: pairName,
        pairSymbol: pairSymbol,
        pairType: pairType,
        priority: values.priority,
        value: values.value,
        elementSelector: values.elementSelector,
      };

      if (isEdit) {
        handleUpdatePairPrice(newPairPrices);
      } else {
        handleAddPairPrice(newPairPrices);
      }
      togglePairPrice();
    },
  });
  const togglePairPrice = useCallback(() => {
    if (showPairPrice) {
      setShowPairPrice(false);
      setEventDataPairPrice(null);
      setIsEdit(false);
    } else {
      setShowPairPrice(true);
      setEventDataPairPrice(null);

      validation.resetForm();
    }
  }, [showPairPrice, validation]);

  const columns = useMemo(
    () => [
      /*           {
            header: "id",
            accessorKey: "id",
            enableColumnFilter: false,
            cell: (cell: any) => (
              <Link
                to="#!"
                className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
              >
                {cell.getValue()}
              </Link>
            ),
          }, */
      {
        header: "pairName",
        accessorKey: "pairName.name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="flex items-center justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
              {cell.row.original.img ? (
                <img
                  src={cell.row.original.img}
                  alt=""
                  className="h-10 rounded-full"
                />
              ) : (
                cell
                  .getValue()
                  .split(" ")
                  .map((word: any) => word.charAt(0))
                  .join("")
              )}
            </div>
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "pairSymbol",
        accessorKey: "pairSymbol.name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "pairType",
        accessorKey: "pairType.name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Priority",
        accessorKey: "priority",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <Dropdown className="relative">
            <Dropdown.Trigger
              className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
              id="usersAction2"
            >
              <MoreHorizontal className="size-3" />
            </Dropdown.Trigger>
            <Dropdown.Content
              placement="right-end"
              className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600"
              aria-labelledby="usersAction1"
            >
              <li>
                <Link
                  data-modal-target="addUserModal"
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  to="#!"
                  onClick={() => {
                    const data = cell.row.original;
                    handleUpdateDataClick(data);
                  }}
                >
                  <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Edit</span>
                </Link>
              </li>
              <li>
                <Link
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  to="#!"
                  onClick={() => {
                    const orderData = cell.row.original;
                    onClickDelete(orderData);
                  }}
                >
                  <Trash2 className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Delete</span>
                </Link>
              </li>
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDeletePairPrice}
      />
      <ToastContainer closeButton={true} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="pairpriceTable">
            <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
              <div className="shrink-0">
                <button
                  type="button"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  onClick={togglePairPrice}
                >
                  <Plus className="inline-block size-4" />{" "}
                  <span className="align-middle">Add PairPrice</span>
                </button>
              </div>
            </div>
            <div className="card-body">
              {pairPrices && pairPrices.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns || []}
                  data={pairPrices || []}
                  customPageSize={5}
                  divclassName="-mx-5 -mb-5 overflow-x-auto"
                  tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                  theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                  thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                  tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                  PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                />
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                    <h5 className="mt-2 mb-1">No PairPrice was added</h5>
                    <p className="mb-0 text-slate-500 dark:text-zink-200"></p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* PairPrice Modal  */}
      <Modal
        show={showPairPrice}
        onHide={togglePairPrice}
        id="pairPriceModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex  items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "Edit PairPrice" : "Add PairPrice"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 ">
          <form
            id="PairPriceForm"
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className=" mb-3 flex flex-row justify-stretch items-center mr-2">
              <label
                htmlFor="pairpriceId"
                className=" mb-2 w-1/4 text-base font-medium"
              >
                PairPrice ID
              </label>
              <input
                type="text"
                id="_id"
                className="ml-2 form-input w-3/4 border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                disabled
                value={validation.values._id || "New ID"}
              />
            </div>

            <div className=" mb-3 flex flex-row justify-between items-center mr-2">
              <label
                htmlFor="PairSymbolInput"
                className=" text-base w-1/4 font-medium"
              >
                PairSymbol
              </label>
              <select
                id="PairSymbolID"
                name="pairSymbol"
                className="ml-2 form-input w-3/4 border-slate-300 focus:outline-none focus:border-custom-500"
                data-choices
                data-choices-search-false
                value={validation.values.pairSymbol._id || null}
                onChange={validation.handleChange}
                required
              >
                {pairSymbols.map((pairsymbolName) => (
                  <option key={pairsymbolName._id} value={pairsymbolName._id}>
                    {pairsymbolName.name}
                  </option>
                ))}
              </select>
            </div>
            <div className=" mb-3 flex flex-row justify-between items-center ">
              <label
                htmlFor="PairNameInput"
                className="text-base w-1/4 font-medium"
              >
                PairName:
              </label>
              <select
                id="PairNameID"
                name="pairName"
                className="ml-2 form-input w-3/4 border-slate-300 focus:outline-none focus:border-custom-500"
                data-choices
                data-choices-search-false
                value={validation.values.pairName._id || null}
                onChange={validation.handleChange}
                required
              >
                {pairNames.map((pairName) => (
                  <option key={pairName._id} value={pairName._id}>
                    {pairName.name}
                  </option>
                ))}
              </select>
            </div>
            <div className=" mb-3 flex flex-row justify-between items-center ">
              <label
                htmlFor="PairTypeInput"
                className="text-base w-1/4 font-medium"
              >
                PairType:
              </label>
              <select
                id="PairTypeID"
                name="pairType"
                className="ml-2 form-input w-3/4 border-slate-300 focus:outline-none focus:border-custom-500"
                data-choices
                data-choices-search-false
                value={validation.values.pairType._id || null}
                onChange={validation.handleChange}
                required
              >
                {pairTypes.map((pairType) => (
                  <option key={pairType._id} value={pairType._id}>
                    {pairType.name}
                  </option>
                ))}
              </select>
            </div>

            <div className=" mb-3 flex flex-row justify-between items-center mr-2">
              <label
                htmlFor="elementSelectorInput"
                className="inline-block w-1/4 mb-2 text-base font-medium"
              >
                Element Selector :
              </label>
              <input
                type="text"
                id="elementSelectorInput"
                className="ml-2 form-input w-3/4  border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter Element Selector"
                name="elementSelector"
                onChange={validation.handleChange}
                value={validation.values.elementSelector || ""}
              />
              {validation.touched.elementSelector &&
              validation.errors.elementSelector ? (
                <p className="text-red-400">
                  {validation.errors.elementSelector}
                </p>
              ) : null}
            </div>
            <div className=" mb-3 flex flex-row justify-between items-center">
              <div className="w-1/4"></div>
              <div className="w-3/4">
                <CheckValue
                  urlAddress={props.urlAddress}
                  elementSelector={validation.values.elementSelector || ""}
                ></CheckValue>
              </div>
            </div>

            <div className=" mb-3 flex flex-row justify-between items-center mr-2">
              <label
                htmlFor="priorityInput"
                className="inline-block w-1/4 mb-2 text-base font-medium"
              >
                Priority :
              </label>
              <input
                type="text"
                id="priorityInput"
                className="ml-2 form-input w-3/4  border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter Priority"
                name="priority"
                onChange={validation.handleChange}
                value={validation.values.priority || ""}
              />
              {validation.touched.priority && validation.errors.priority ? (
                <p className="text-red-400">{validation.errors.priority}</p>
              ) : null}
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                onClick={togglePairPrice}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update PairPrice" : "Add PairPrice"}
              </button>
            </div>
            
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ExchangesPairPrices;
