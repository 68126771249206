import React, { Fragment, useState } from "react";

export const MainSettings = () => {
    const [IsActive,setIsActive]=useState(true);
  return (
    <Fragment>
      <div className="flex items-center">
        <div className="relative inline-block w-10 align-middle transition duration-200 ease-in ltr:mr-2 rtl:ml-2">
          <input
            type="checkbox"
            name="customDefaultSwitch"
            id="customDefaultSwitch"
            className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-full appearance-none cursor-pointer border-slate-200 dark:border-zink-500 bg-white/80 dark:bg-zink-400 peer/published checked:bg-white dark:checked:bg-white ltr:checked:right-0 rtl:checked:left-0 checked:bg-none checked:border-custom-500 dark:checked:border-custom-500 arrow-none"
            defaultChecked={IsActive}
           //checked={IsActive}
          />
          <label
            htmlFor="customDefaultSwitch"
            className="block h-5 overflow-hidden duration-300 ease-linear border rounded-full cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-custom-500 peer-checked/published:border-custom-500"
          ></label>
        </div>
        <label
          htmlFor="customDefaultSwitch"
          className="inline-block text-base font-medium cursor-pointer"
        >
          Active
        </label>
      </div>
    </Fragment>
  );
};
