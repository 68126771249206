import {
  createSlice,
  nanoid,
  PayloadAction,createEntityAdapter, 
  EntityId
} from "@reduxjs/toolkit";

import { IPairPrices } from "Common/types";
import { RootState } from "../slices";

const pairPricesAdapter = createEntityAdapter<IPairPrices,EntityId>({
  selectId: (pairPrice) => pairPrice.id
});
const initialState = pairPricesAdapter.getInitialState();

  const pairPricesSlice = createSlice({
    name: 'pairPrices',
    initialState,
    reducers: {
      addPairPrice:  {
        reducer: pairPricesAdapter.addOne,
        prepare: (pair: Omit<IPairPrices, 'id'>) => ({
          payload: {
            id: nanoid(),
            elementSelector: pair.elementSelector,
            pairName:pair.pairName,
            pairSymbol:pair.pairSymbol,
            pairType:pair.pairType,
            value:pair.value,
            priority:pair.priority
          }
        })
      },     
      removePairPrice: pairPricesAdapter.removeOne,
      updatePairPrice: pairPricesAdapter.updateOne,
      removeAllPairPrice: pairPricesAdapter.removeAll
    }
  });

export const { addPairPrice,removePairPrice,updatePairPrice,removeAllPairPrice } = pairPricesSlice.actions;

export const {
  selectAll: selectAllPairPrices,
  selectById: selectPairPriceById,
} = pairPricesAdapter.getSelectors((state: RootState) => state.pairPrices);

export const pairPricesReducer = pairPricesSlice.reducer;
