import axios from "axios";
import { ICurrencyTypes } from "Common/types";

const apiurl=process.env.REACT_APP_SERVER_URL

export const getPairSymbol = () => {
  const token = localStorage.getItem("token");
  
  if (token) {
    try {
      
      const url = `${apiurl}PairSymbols`;
      const response = axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching PairSymbols:", error);
    }
  } else {
  }
};

export const deletePairSymbol = (PairSymbolId: string) => {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      const url = `${apiurl}PairSymbols/${PairSymbolId}`;
      const response = axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error deleting PairSymbols:", error);
    }
  }
};

export const addPairSymbol = (PairSymbol: ICurrencyTypes) => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const url = `${apiurl}PairSymbols`;
      const response = axios.post(url, PairSymbol, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      console.error("Error adding new PairSymbols:", error);
    }
  }
};

export const updatePairSymbol= (PairSymbol: ICurrencyTypes) => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const url = `${apiurl}PairSymbols/${PairSymbol._id}`;
      const response = axios.put(url, PairSymbol, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      console.error("Error updating  PairSymbols:", error);
    }
  }
};
