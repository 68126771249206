import axios from "axios";
import { IUsers } from "Common/types";


const apiurl=process.env.REACT_APP_SERVER_URL


export const login = (user: IUsers) => {
  

    try {
      const url = `${apiurl}users/login`;
      const response = axios.post(url, user);

      return response;
    } catch (error) {
      console.error("Error adding new exchangeNames:", error);
    }
  
};


export const validateToken = async (token: string): Promise<boolean> => {
  try {
    const url: string = `${apiurl}validateToken`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.message === 'Token is valid') {
      return true; 
    } else {
      return false; 
    }
  } catch (error) {
    console.error("Error validating token:", error);
    return false; 
  }
};