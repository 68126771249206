import React, { ReactNode, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { validateToken } from "../services/usersService";

interface AuthProtectedProps {
  children: ReactNode;
}

const AuthProtected: React.FC<AuthProtectedProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);


  useEffect(()=> {
    const fetchToken = async () => {
      const token = localStorage.getItem('token');

      if (token) {
        const res = await validateToken(token);
        setIsLoggedIn(res);
      }
      setIsLoading(false);
    };

    fetchToken();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthProtected;
