import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// login
import LoginReducer from "./auth/login/reducer";

// register
import RegisterReducer from "./auth/register/reducer";

// userProfile
import ProfileReducer from "./auth/profile/reducer";

//exchanges
import { exchangesReducer } from "state/exchangeSlice";
import { exchangesNamesReducer } from "state/exchangeNamesSlice";
import { pairPricesReducer} from "state/pairPricesSlice"
const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    Register: RegisterReducer,
    Profile: ProfileReducer,
    exchanges: exchangesReducer,
    pairPrices: pairPricesReducer, 
    exchaneNames:exchangesNamesReducer,
});


export type RootState = ReturnType<typeof rootReducer>;


export default rootReducer;