import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Link } from "react-router-dom";
import { Dropdown } from "Common/Components/Dropdown";
import TableContainer from "Common/TableContainer";
// Icons
import { Search,CopyPlus, Trash2, Plus, MoreHorizontal, FileEdit } from "lucide-react";
import Modal from "Common/Components/Modal";
import DeleteModal from "Common/DeleteModal";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import {
  ICurrencyTypes,
  IExchangeNames,
  IExchanges,
} from "Common/types";
import { useSelector, useDispatch } from "react-redux";
import {
  addExchanges,
  deleteExchanges,
  fetchExchanges,
  selectAllExchanges,
  updateExchanges,
} from "state/exchangeSlice";
import { AppDispatch } from "state/store";
import { RootState } from "slices";
import { getExchangeName } from "services/exchangeNamesService ";
import { getCurrencyType } from "services/currencyTypeService";
import {
  addPairPrice,
  removeAllPairPrice,
  selectAllPairPrices,
} from "state/pairPricesSlice";
import ExchangesPairPrices from "./components/exchangesPairPrices";

const Exchanges = () => {
  //const [exchanges, setexchanges] = useState<Iexchanges[]>([]);
  const [exchangeNames, setExchangeNames] = useState<IExchangeNames[]>([]);
  const [currencyTypes, setCurrencyTypes] = useState<ICurrencyTypes[]>([]);


  const dispatch = useDispatch<AppDispatch>();
  const exchanges = useSelector(selectAllExchanges);
  const exchangesStatus = useSelector(
    (state: RootState) => state.exchanges.status
  );
  let pairPrices = useSelector(selectAllPairPrices);
  const error = useSelector((state: RootState) => state.exchanges.error);

  useEffect(() => {
    fetchCurrencyTypes();
 
    fetchExchangeNames();
    if (exchangesStatus === "idle") {
      dispatch(fetchExchanges());
    }
  }, [dispatch]);

  const fetchCurrencyTypes = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await getCurrencyType();

        setCurrencyTypes(response?.data.currencyTypes);
      } catch (error) {
        console.error("Error fetching currency types:", error);
      }
    }
  };


  const fetchExchangeNames = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await getExchangeName();

        setExchangeNames(response?.data.exchangeNames);
      } catch (error) {
        console.error("Error fetching pair names:", error);
      }
    }
  };

 

  const [eventData, setEventData] = useState<IExchanges | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell._id) {
      setEventData(cell);
      handleDelete();
    }
  };
  const onClickDuplicate = (cell: any) => {
    if (cell._id) {
      setEventData(cell);
      const newExchange: IExchanges = {
        _id:"",
        faName: cell.faName,
        currencyType: {_id:cell.currencyType._id,name:cell.currencyType.name},
        exchangeName: {_id:cell.exchangeName._id,name:cell.exchangeName.name,isActive:false,URL:cell.exchangeName.URL},
        urlAddress: cell.urlAddress,
        pairPrices: cell.pairPrices,
      };
      handleAdd(newExchange);
      console.log(newExchange)
    }
  };
  const handleDelete = async () => {
    if (eventData) {
      setDeleteModal(false);
      const token = localStorage.getItem("token");

      if (token) {
        try {
          dispatch(deleteExchanges(eventData));
        } catch (error) {
          console.error("Error deleting exchanges:", error);
        }
      }
    }
  };
  //

  // Update Data
  const handleUpdateDataClick = (ele: IExchanges) => {
    setEventData({ ...ele });
    ele.pairPrices.map((pairPrice) => {
      dispatch(addPairPrice(pairPrice));
    });
    setIsEdit(true);
    setShow(true);
  };

  const handleUpdate = async (updateData: IExchanges) => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        dispatch(updateExchanges(updateData));
      } catch (error) {
        console.error("Error updating currency type:", error);
      }
    }
  };

  const handleAdd = async (newexchange: IExchanges) => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        dispatch(addExchanges(newexchange));
       
      } catch (error) {
        console.error("Error adding new exchanges:", error);
      }
    }
  };

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: (eventData && eventData._id) || "",
      faName: (eventData && eventData.faName) || "",
      currencyType: (eventData! && eventData.currencyType) || "",
      exchangeName: (eventData! && eventData.exchangeName) || "",
      urlAddress: (eventData && eventData.urlAddress) || "",
      pairPrices: (eventData && eventData.pairPrices) || [],
    },
    validationSchema: Yup.object({
      faName: Yup.string().required("Please Enter Name"),
    }),

    onSubmit: (values: IExchanges) => {
      let currencyTypeValue;
      let exchangeNameValue;
      if (typeof values.currencyType === "string") {
        currencyTypeValue =
          values.currencyType == null
            ? currencyTypes[0]
            : currencyTypes.find(
                (item) => item._id === String(values.currencyType)
              );
      } else {
        currencyTypeValue =
          values.currencyType == null
            ? currencyTypes[0]
            : currencyTypes.find(
                (item) => item._id === values.currencyType._id
              );
      }

      const currencyType: ICurrencyTypes =
        currencyTypeValue || currencyTypes[0];

      if (typeof values.exchangeName === "string") {
        exchangeNameValue =
          values.exchangeName == null
            ? exchangeNames[0]
            : exchangeNames.find(
                (item) => item._id === String(values.exchangeName)
              );
      } else {
        exchangeNameValue =
          values.exchangeName == null
            ? exchangeNames[0]
            : exchangeNames.find(
                (item) => item._id === values.exchangeName._id);
      }

      const exchangeName: IExchangeNames =
        exchangeNameValue || exchangeNames[0];

      const newExchange: IExchanges = {
        _id:"",
        faName: values.faName,
        currencyType: currencyType,
        exchangeName: exchangeName,
        urlAddress: values.urlAddress,
        pairPrices: pairPrices,
      };

      if (isEdit) {
        const updateExchange={
           ...newExchange,
            _id: eventData!._id,
          };

        
        handleUpdate(updateExchange);
      } else {
        handleAdd(newExchange);
      }
      toggle();
    },
  });
  //
  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData(null);
      setIsEdit(false);
      dispatch(removeAllPairPrice());
    } else {
      setShow(true);
      setEventData(null);
      validation.resetForm();
    }
  }, [show, validation]);

  const columns = useMemo(
    () => [
      {
        header: "ID",
        accessorKey: "_id",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link
            to="#!"
            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
          >
            {cell.getValue()}
          </Link>
        ),
      },
      {
        header: "Name",
        accessorKey: "faName",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="flex items-center justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
              {cell.row.original.img ? (
                <img
                  src={cell.row.original.img}
                  alt=""
                  className="h-10 rounded-full"
                />
              ) : (
                cell
                  .getValue()
                  .split(" ")
                  .map((word: any) => word.charAt(0))
                  .join("")
              )}
            </div>
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "currencyType",
        accessorKey: "currencyType.name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "exchangeName",
        accessorKey: "exchangeName.name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "URL Address",
        accessorKey: "urlAddress",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <Dropdown className="relative">
            <Dropdown.Trigger
              className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
              id="usersAction1"
            >
              <MoreHorizontal className="size-3" />
            </Dropdown.Trigger>
            <Dropdown.Content
              placement="right-end"
              className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600"
              aria-labelledby="usersAction1"
            >
              <li>
                <Link
                  data-modal-target="addUserModal"
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  to="#!"
                  onClick={() => {
                    const data = cell.row.original;
                    handleUpdateDataClick(data);
                  }}
                >
                  <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Edit</span>
                </Link>
              </li>
              <li>
                <Link
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  to="#!"
                  onClick={() => {
                    const orderData = cell.row.original;
                    onClickDuplicate(orderData);
                  }}
                >
                  <CopyPlus className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Duplicate</span>
                </Link>
              </li>
              <li>
                <Link
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  to="#!"
                  onClick={() => {
                    const orderData = cell.row.original;
                    onClickDelete(orderData);
                  }}
                >
                  <Trash2 className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Delete</span>
                </Link>
              </li>
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <BreadCrumb title="Exchanges" pageTitle="Exchanges" />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="card-body">
              <div className="flex items-center">
                <h6 className="text-15 grow">Exchanges List</h6>
                <div className="shrink-0">
                  <button
                    type="button"
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                    onClick={toggle}
                  >
                    <Plus className="inline-block size-4" />{" "}
                    <span className="align-middle">Add Exchange</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
              <form action="#!">
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="xl:col-span-3 xl:col-start-10">
                    <div className="flex gap-2 xl:justify-end">
                      <div></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="card-body">
              {exchanges && exchanges.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns || []}
                  data={exchanges || []}
                  customPageSize={10}
                  divclassName="-mx-5 -mb-5 overflow-x-auto"
                  tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                  theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                  thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                  tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                  PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                />
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                    <p className="mb-0 text-slate-500 dark:text-zink-200">
                      We've searched more than 199+ users We did not find any
                      users for you search.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* User Modal  */}
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[60rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex  items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "Edit Exchange" : "Add Exchange"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 ">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3 flex justify-between">
              <label
                htmlFor="userId"
                className=" mb-2 w-1/2 text-base font-medium"
              >
                Exchange ID
              </label>
              <input
                type="text"
                id="_id"
                className="form-input w-1/2 border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                disabled
                value={validation.values._id || "New ID"}
              />
            </div>

            <div className="flex flex-row justify-between items-center mb-3">
              <div className="w-1/2 mb-3 flex flex-row justify-between items-center mr-2">
                <label
                  htmlFor="userNameInput"
                  className=" text-base font-medium"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="userNameInput"
                  className="form-input w-2/3 border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter name"
                  name="faName"
                  onChange={validation.handleChange}
                  value={validation.values.faName || ""}
                />
                {validation.touched.faName && validation.errors.faName ? (
                  <p className="text-red-400">{validation.errors.faName}</p>
                ) : null}
              </div>
              <div className="w-1/2 mb-3 flex flex-row justify-between items-center ">
                <label
                  htmlFor="ExchangeNameInput"
                  className="text-base font-medium"
                >
                  Exchange Name:
                </label>
                <select
                  id="ExchangeNameID"
                  name="exchangeName"
                  className="form-input w-2/3 border-slate-300 focus:outline-none focus:border-custom-500"
                  data-choices
                  data-choices-search-false
                  value={validation.values.exchangeName._id || null}
                  onChange={validation.handleChange}
                  required
                >
                  {exchangeNames.map((exchangeName) => (
                    <option key={exchangeName._id} value={exchangeName._id}>
                      {exchangeName.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center mb-3 ">
              <div className="w-1/2 mb-3 flex flex-row justify-between items-center mr-2">
                <label
                  htmlFor="urlAddressInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  URL Address
                </label>
                <input
                  type="text"
                  id="urlAddressInput"
                  className="form-input w-2/3 border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter URL Address"
                  name="urlAddress"
                  onChange={validation.handleChange}
                  value={validation.values.urlAddress || ""}
                />
                {validation.touched.urlAddress &&
                validation.errors.urlAddress ? (
                  <p className="text-red-400">{validation.errors.urlAddress}</p>
                ) : null}
              </div>

              <div className="w-1/2 mb-3 flex flex-row justify-between items-center ">
                <label
                  htmlFor="CurrencyTypeInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Currency Type:
                </label>
                <select
                  id="currencyTypeID"
                  name="currencyType"
                  className="form-input w-2/3 border-slate-300 focus:outline-none focus:border-custom-500"
                  data-choices
                  data-choices-search-false
                  value={validation.values.currencyType._id || null}
                  onChange={validation.handleChange}
                  required
                >
                  {currencyTypes.map((currencyType) => (
                    <option key={currencyType._id} value={currencyType._id}>
                      {currencyType.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update Exchange" : "Add Exchange"}
              </button>
            </div>
          </form>
          <ExchangesPairPrices urlAddress={eventData?.urlAddress||""}></ExchangesPairPrices>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Exchanges;
