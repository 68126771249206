import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  EntityState,
  EntityId,
} from "@reduxjs/toolkit";

import {
  addExchangeName,
  deleteExchangeName,
  getExchangeName,
  updateExchangeName,
} from "../services/exchangeNamesService ";
import { IExchangeNames } from "Common/types";
import { RootState } from "../slices";

const exchangeNamesAdapter = createEntityAdapter<IExchangeNames, EntityId>({
  selectId: (e: any) => e._id,
  sortComparer: (a, b) => a._id.localeCompare(b._id),
});

const initialState = exchangeNamesAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchExchangeNames = createAsyncThunk(
  "exchangeNames/fetchExchangeNames",
  async () => {
    const response = await getExchangeName();
    return response?.data.exchangeNames;
  }
);

export const updateExchangeNames = createAsyncThunk(
  "exchangeNames/updateExchangeNames",
  async (exchangeUpdate: IExchangeNames) => {
    const response = await updateExchangeName(exchangeUpdate);
    return response?.data.exchangeName;
  }
);

export const addExchangeNames = createAsyncThunk(
  "exchangeNames/addExchangeNames",
  async (exchangeAdd: IExchangeNames) => {
    const response = await addExchangeName(exchangeAdd);
    return response?.data.exchangeName;
  }
);
export const deleteExchangeNames = createAsyncThunk(
  "exchangeNames/deleleExchangeNames",
  async (exchangeDelete: IExchangeNames) => {
    const response = await deleteExchangeName(exchangeDelete._id);
    
    return exchangeDelete._id;
  }
);

const exchangeNamesSlice = createSlice({
  name: "exchangeNames",
  initialState: initialState,
  reducers: {
    exchangeAdded(state, action) {
      const exchange = action.payload;
      state.entities[exchange._id] = exchange;
    },
    exchangeUpdated(state, action) {
      const updatedExchange = action.payload;
      const exchangeId = updatedExchange._id;
      state.entities[exchangeId] = updatedExchange;
    },
    exchangeDeleted(state, action) {
      delete state.entities[action.payload._id];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExchangeNames.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchExchangeNames.fulfilled, (state, action) => {
        state.status = "idle";
        exchangeNamesAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchExchangeNames.rejected, (state, action) => {
        state.status = "failed";
        // state.error = action.error.message;
      })
      .addCase(addExchangeNames.fulfilled, (state, action) => {
        console.log(action.payload);
        exchangeNamesAdapter.addOne(state, action.payload);
      })
      .addCase(updateExchangeNames.fulfilled, (state, action) => {
        const { _id, ...changes } = action.payload;
        exchangeNamesAdapter.updateOne(state, { id: _id, changes });
        
      })
      .addCase(deleteExchangeNames.fulfilled, (state, action) => {
        exchangeNamesAdapter.removeOne(state,  action.payload );
      });
  },
});
export const { exchangeAdded, exchangeUpdated, exchangeDeleted } =
exchangeNamesSlice.actions;
export const { selectAll: selectAllExchangeNames, selectById: selectExchangeNameById } =
exchangeNamesAdapter.getSelectors((state: RootState) => state.exchaneNames);

export const exchangesNamesReducer = exchangeNamesSlice.reducer;
