import axios from "axios";
import { ICheck } from "Common/types";

const apiurl=process.env.REACT_APP_SERVER_URL
export const getCheckValue = (Checkvalue: ICheck) => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const url = `${apiurl}checkvalue`;
        const response = axios.post(url, Checkvalue, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        return response;
      } catch (error) {
        console.error("Error adding new checkvalue:", error);
      }
    }
  };