import axios from "axios";
import { IExchangeNames } from "Common/types";

const apiurl=process.env.REACT_APP_SERVER_URL

export const getExchangeName = () => {
  const token = localStorage.getItem("token");
  
  if (token) {
    try {
      
      const url = `${apiurl}exchangeNames`;
      const response = axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching exchangeNames:", error);
    }
  } else {
  }
};

export const deleteExchangeName = (exchangeId: string) => {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      const url = `${apiurl}exchangeNames/${exchangeId}`;
      const response = axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error deleting exchangeNames:", error);
    }
  }
};

export const addExchangeName = (exchange: IExchangeNames) => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const url = `${apiurl}exchangeNames`;
      const response = axios.post(url, exchange, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      console.error("Error adding new exchangeNames:", error);
    }
  }
};

export const updateExchangeName= (exchange: IExchangeNames) => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const url = `${apiurl}exchangeNames/${exchange._id}`;
      const response = axios.put(url, exchange, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      console.error("Error updating  exchangeNames:", error);
    }
  }
};
