import {  MessageSquare, MonitorDot, Settings } from "lucide-react";

const menuData: any = [
    {
        label: 'Rialzoom Admin',
        isTitle: true,
    },
    {
        id: "Exchanges",
        label: 'Administration',
        link: "/#",
        icon: <MonitorDot />,
        subItems: [
            {
                id: 'Exchanges',
                label: 'Exchanges',
                link: '/exchanges',
                parentId: "Exchanges"
            },
            {
                id: '/ExchangeName',
                label: 'ExchangeName',
                link: '/ExchangeName',
                parentId: "Exchanges"
            },
            {
                id: 'CurrencyType',
                label: 'CurrencyType',
                link: '/CurrencyType',
                parentId: "Exchanges"
            },
            {
                id: 'PairName',
                label: 'PairName',
                link: '/PairName',
                parentId: "Exchanges"
            },
            {
                id: 'PairSymbol',
                label: 'PairSymbol',
                link: '/PairSymbol',
                parentId: "Exchanges"
            },
            {
                id: 'PairType',
                label: 'PairType',
                link: '/PairType',
                parentId: "Exchanges"
            },
            {
                id: 'DataTable',
                label: 'DataTable',
                link: '/datatable',
                parentId: "Exchanges"
            },
            
            
        ]
    },

    {
        label: 'Robot Settings',
        isTitle: true,
    },
    {
        id: 'settings',
        label: 'Settings',
        icon: <Settings />,
        link: '/settings',
        parentId: 2
    },
];

export { menuData };